import React from "react"
import styled from "styled-components"
import { FormattedHTMLMessage, useIntl } from "gatsby-plugin-intl"

import BuildingBlocks from "../assets/BuildingBlocks.svg"
import DreamWorld from "../assets/DreamWorld.svg"
import FormingIdeas from "../assets/FormingIdeas.svg"
import MissionImpossible from "../assets/MissionImpossible.svg"

import CoreValues from "../components/CoreValues"
import Footer from "../components/Footer"
import Layout from "../components/Layout"
import Meta from "../components/Meta"
import Navbar from "../components/Navbar"
import Pillars from "../components/Pillars"
import Statement from "../components/molecules/Statement"
import devices from "../helpers/devices"
import { Button } from "../components/simpa-design-kit"
import { H1, HeroP } from "../components/atoms/ResTypography"
import { PageProps } from "../helpers/props"

const SectionHero = styled.section`
  .Simpa {
    position: absolute;
    display: none;

    em {
      font-family: "GT Haptik Rotalic";
      font-size: 24rem;
      font-weight: bold;
      color: var(--primary-color);
    }

    @media ${devices.tablet} {
      display: block;

      bottom: -22%;
    }

    @media ${devices.widescreen} {
      bottom: -18.5%;
    }

    @media ${devices.fullhd} {
      bottom: -20%;
    }
  }
`

const SectionCoreValues = styled.section`
  .ilc {
    display: flex;
    justify-content: center;

    .il {
      width: 85%;
    }
  }
`

const SectionPillars = styled.section`
  .ilc {
    display: flex;
    justify-content: center;
  }
`

const AboutPage: React.FunctionComponent<PageProps> = ({ location }) => {
  const intl = useIntl()
  const title = intl.formatMessage({ id: "Company" })

  function onCallToActionClick() {
    const e = document.querySelector("#mission")
    if (e) e.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <Layout>
      <Meta pathname={location.pathname} title={title} />
      <Navbar isFixedTop={false} />
      <SectionHero className="section is-clipped" id="hero">
        <div className="container">
          <div className="columns">
            <div className="column is-two-thirds-tablet is-three-fifths-desktop">
              <H1 className="title">{title}</H1>
              <HeroP>
                <FormattedHTMLMessage id="h_do_it_simpa" />
              </HeroP>
              <HeroP>
                <FormattedHTMLMessage id="h_we_chase_new" />
              </HeroP>
              <HeroP>
                <FormattedHTMLMessage id="h_tech_and_design" />
              </HeroP>
              <HeroP>
                <FormattedHTMLMessage id="h_are_you_curious" />
              </HeroP>
              <Button className="primary" onClick={onCallToActionClick}>
                {intl.formatMessage({ id: "Get to know" })}
              </Button>
            </div>
            <div className="column is-relative">
              <div className="Simpa">
                <em>Simpa</em>
              </div>
            </div>
          </div>
        </div>
      </SectionHero>
      <section className="section" id="mission" style={{ backgroundColor: "#edf7f0" }}>
        <div className="container">
          <Statement
            rightIcon={<img alt="" className="il" src={MissionImpossible} />}
            message={<FormattedHTMLMessage id="h_simpa_mission" />}
            title="Mission"
          />
        </div>
      </section>
      <section className="section" id="vision">
        <div className="container">
          <Statement
            leftIcon={<img alt="" className="il" src={FormingIdeas} />}
            message={<FormattedHTMLMessage id="h_simpa_vision" />}
            title="Vision"
          />
        </div>
      </section>
      <SectionCoreValues className="section" id="core-values" style={{ backgroundColor: "#edf7f0" }}>
        <div className="container">
          <div className="columns">
            <div className="column is-12-tablet is-8-desktop">
              <CoreValues />
            </div>
            <div className="column has-text-right ilc is-hidden-touch">
              <img alt="" className="il" src={DreamWorld} />
            </div>
          </div>
        </div>
      </SectionCoreValues>
      <SectionPillars className="section" id="pillars">
        <div className="container">
          <div className="columns">
            <div className="column ilc is-4 is-hidden-touch">
              <img alt="" className="il" src={BuildingBlocks} />
            </div>
            <div className="column">
              <Pillars />
            </div>
          </div>
        </div>
      </SectionPillars>
      <Footer />
    </Layout>
  )
}

export default AboutPage
