import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import devices from "../helpers/devices"
import { pallozzo } from "../helpers/mixin"

const Styled = styled.div`
  h2 {
    margin: 0;
    font-size: 6rem;
    text-align: center;
    -webkit-text-stroke: 2px var(--primary-dark-color);

    em {
      font-family: GT Haptik Rotalic;
      color: transparent;
    }

    @media ${devices.tablet} {
      font-size: 6rem;
    }

    @media ${devices.widescreen} {
      font-size: 8rem;
    }

    @media ${devices.fullhd} {
      font-size: 9.5rem;
    }
  }

  .circles {
    display: flex;
    justify-content: center;

    .circle {
      display: flex;
      align-items: center;

      ${pallozzo(6)};

      background-color: var(--grey-2-color);
      box-shadow: 0 0.5em 1em -0.125em rgba(51, 51, 51, 0.1), 0 0px 0 1px rgba(51, 51, 51, 0.02);
      margin-right: 1rem;
      padding: 0.75rem;
      transform: rotate(-14deg);

      justify-content: center;
      line-height: 1.5rem;
      text-align: center;

      @media ${devices.tablet} {
        margin-right: 5rem;
      }

      @media ${devices.widescreen} {
        ${pallozzo(8)};
      }

      &:last-child {
        margin-right: 0;
      }

      h3 {
        margin: 0;

        color: #fff;
        font-size: 1rem;
        font-weight: 500;

        @media ${devices.widescreen} {
          font-size: 1.25rem;
        }
      }
    }
  }
`

const Pillars: React.FunctionComponent = () => {
  const intl = useIntl()

  return (
    <Styled className="columns is-mobile">
      <div className="column">
        <div className="circles up">
          <div className="circle">
            <h3>{intl.formatMessage({ id: "users first" })}</h3>
          </div>
          <div className="circle">
            <h3>{intl.formatMessage({ id: "work is fun" })}</h3>
          </div>
          <div className="circle">
            <h3>{intl.formatMessage({ id: "star team" })}</h3>
          </div>
        </div>
        <h2>
          <em>{intl.formatMessage({ id: "Pillars." })}</em>
        </h2>
        <div className="circles down">
          <div className="circle">
            <h3>{intl.formatMessage({ id: "sinergy" })}</h3>
          </div>
          <div className="circle">
            <h3>{intl.formatMessage({ id: "smart feedback" })}</h3>
          </div>
          <div className="circle">
            <h3>{intl.formatMessage({ id: "manage with a soul" })}</h3>
          </div>
        </div>
      </div>
    </Styled>
  )
}

export default Pillars
