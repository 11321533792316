import React from "react"
import styled from "styled-components"

import { H2 } from "../atoms/ResTypography"
import devices from "../../helpers/devices"

interface StatementProps {
  justifyContent?: string
  leftIcon?: React.ReactNode
  message: React.ReactNode | React.ReactNode[]
  rightIcon?: React.ReactNode
  title: React.ReactNode | React.ReactNode[]
}

const Styled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: ${props => props.justifyContent};

  @media ${devices.tablet} {
    flex-direction: row;
  }

  .left-icon {
    order: 1;

    @media ${devices.tablet} {
      padding-right: 2rem;
    }
  }

  .right-icon {
    order: 0;

    @media ${devices.tablet} {
      order: 1;

      padding-left: 2rem;
    }
  }

  .left-icon,
  .right-icon {
    max-width: 25%;
    margin-bottom: 1.5rem;

    @media ${devices.tablet} {
      max-width: 20%;
      margin-bottom: 0;
    }
  }

  .scontent {
    order: 1;

    max-width: 100%;

    @media ${devices.tablet} {
      max-width: 80%;
    }

    h2 {
      margin: 0 0 1.5rem 0;

      font-weight: 600;
    }

    div {
      font-size: 1rem;

      @media ${devices.desktop} {
        font-size: 1.125rem;
      }
    }
  }
`

const Statement: React.FunctionComponent<StatementProps> = ({
  justifyContent,
  leftIcon,
  message,
  rightIcon,
  title,
}) => {
  function getLeftIcon() {
    if (leftIcon) {
      return <div className="left-icon">{leftIcon}</div>
    }
  }

  function getRightIcon() {
    if (rightIcon) {
      return <div className="right-icon">{rightIcon}</div>
    }
  }

  return (
    <div className="columns">
      <div className="column">
        <Styled justifyContent={justifyContent}>
          {getLeftIcon()}
          <div className="scontent">
            <H2>{title}</H2>
            <div>{message}</div>
          </div>
          {getRightIcon()}
        </Styled>
      </div>
    </div>
  )
}

Statement.defaultProps = {
  justifyContent: "center",
} as Partial<StatementProps>

export default Statement
